import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  public devMovDate:Date
  public devWebIDate:Date
  public devProjXadrezIDate:Date

  constructor() { }

  ngOnInit(): void {
    this.devMovDate = new Date("2020-11-18")
    this.devWebIDate = new Date("2021-01-25")
    this.devProjXadrezIDate = new Date("2020-12-10")
  }

}
