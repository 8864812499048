<div>
    <div class="container-fluid d-flex align-items-center flex-column bg-custom-primary shadow">
        <!--
        <img src="../../../assets/meu_perfil.png" class="mt-3 shadow rounded-circle img-perfil">
        <h1 class="mt-3">Olá! Bem-vind@!</h1>
        <p>Professor Antônio J. L. Batista</p>
        -->
        <div class="jumbotron mt-3 nobg">
            <div class="row align-items-center">
                <div class="col-md-2">
                    <img src="../../../assets/perfil-2.png" class="img-fluid">
                </div>
                <div class="col-md-10">
                    <h1 class="display-4">Olá! Bem-vind@!</h1>
                    <p class="lead">Seja bem-vindo ou bem-vinda ao meu site.</p> 
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr class="my-4">
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <p>Meu nome é Antônio e este site disponibiliza os conteúdos das disciplinas que leciono. 
                       Você também encontra informações de projetos que coordeno ou que já coordenei.</p>
                </div>
                <div class="col-md-4">
                    <a class="btn btn-custom-secondary btn-lg" href="/contato" role="button">Entre em contato</a>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid d-flex justify-content-center p-5 align-items-center">
        <h3 class="">Acompanhe as Disciplinas</h3>
    </div>

    <div class="container  mb-5">
        <div class="card-deck row justify-content-center">
            <div class="card curso-bg zoom col-md-4 col-sm-4 border-0">
                <img src="../../../assets/icon-desenvolvimento-para-dispositivos-moveis.png" class="card-img-top rounded-circle">
                <div class="card-body shadow-sm">
                    <h5 class="card-title">Desenvolvimento para Dispositivos Móveis</h5>
                    <p class="card-text">Pós-Graduação em Desenvolvimento de Sistemas Web (<i>latu-senso</i>)</p>
                    <button mat-raised-button class="bg-custom-secondary">Saiba Mais</button>
                </div>
                <div class="card-footer">
                    <small class="text-muted">Atualizado em {{devMovDate | date:'dd/LL/yyyy' }}</small>
                </div>
            </div>
            
            <div class="card curso-bg zoom col-md-4 col-sm-4 border-0">
                <img src="../../../assets/icone-desenvolvimento-web-i.png" class="card-img-top rounded-circle">
                <div class="card-body shadow-sm">
                    <h5 class="card-title">Desenvolvimento Web I</h5>
                    <p class="card-text">Curso Técnico em Informática 2º período</p>
                    <button routerLink="dev-web-i" mat-raised-button class="bg-custom-secondary">Saiba Mais</button>
                </div>
                <div class="card-footer">
                    <small class="text-muted">Atualizado em {{devWebIDate | date:'dd/LL/yyyy' }}</small>
                </div>
            </div>
        </div>
    </div>

    <div class="container bg-custom-primary">
        <hr class="my-5 bg-custom-primary">
    </div>

    <div class="container-fluid d-flex justify-content-center p-5 align-items-center">
        <h3 class="">Acompanhe os Projetos</h3>
    </div>

    <div class="container mb-5">
        <div class="card-deck row justify-content-center">
            <div class="card curso-bg zoom col-md-4 col-sm-4 border-0">
                <img src="../../../assets/icone-xadrez-no-if.png" class="card-img-top rounded-circle">
                <div class="card-body shadow-sm">
                    <h5 class="card-title">Xadrez no IF</h5>
                    <p class="card-text">Projeto de Extensão</p>
                    <button routerLink="xadrez" mat-raised-button class="bg-custom-secondary">Saiba Mais</button>
                </div>
                <div class="card-footer">
                    <small class="text-muted">Atualizado em {{devProjXadrezIDate | date:'dd/LL/yyyy' }}</small>
                </div>
            </div>
        </div>
    </div>
</div>
