<div class="container p-4">

    <div class="m-2">
    <span class="badge badge-danger">Atenção!!</span> Esta página apresenta alguns conteúdos das aulas. 
    O discente deve acompanhar os demais conteúdos, as atividades e 
    outras informações pelo ambiente virtual da disciplina no SIGAA.
    </div>  

    <div class="card-group" *ngFor="let aula of aulas; index as i">
        
        <div class="card nobg border-0">
            <div class="card-header">
                <h3>{{aula.titulo}}</h3>
            </div>
            <div class="card-body">
                <p class="card-text">{{aula.descricao}}</p>
                <div class="embed-responsive embed-responsive-21by9">
                    <iframe class="embed-responsive-item" [src]="youtubeSafeUrl[i]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

