import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Aulas } from './aulas.model';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dev-web-i',
  templateUrl: './dev-web-i.component.html',
  styleUrls: ['./dev-web-i.component.css']
})
export class DevWebIComponent implements OnInit {

  aulas:Aulas[]
  youtubeSafeUrl:SafeResourceUrl[]

  constructor(private http:HttpClient, private dom:DomSanitizer) { }

  ngOnInit(): void {
    this.youtubeSafeUrl = []
    this.getJsonData().subscribe(dados => {
      this.aulas=dados.aulas
      
        for(let aula of this.aulas) {
          this.youtubeSafeUrl.push(this.domSanitizer(aula.youtubeUrl))
        }
      })
    }

  getJsonData():Observable<any> {
    return this.http.get("../../assets/aulas.json")
  }

  domSanitizer(url:string):SafeResourceUrl {
    return this.dom.bypassSecurityTrustResourceUrl(url)
  }

}
