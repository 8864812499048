<div class="p-3 container-fluid d-flex flex-column align-items-center bg-custom-primary">
    Desenvolvido e mantido por Antônio J. L. Batista
    <div>
        <mat-icon class="text-custom-terciary" fontSet="fab" fontIcon="fa-angular"></mat-icon>
        <mat-icon class="text-custom-terciary" fontSet="fab" fontIcon="fa-bootstrap"></mat-icon>
        <mat-icon class="text-custom-terciary" fontSet="fab" fontIcon="fa-html5"></mat-icon>
        <mat-icon class="text-custom-terciary" fontSet="fab" fontIcon="fa-css3-alt"></mat-icon>
        2020
    </div>
</div>
