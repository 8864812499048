
<nav class="navbar navbar-expand-lg navbar-dark bg-custom-primary shadow">
    
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Início </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/formacao" routerLinkActive="active">Profissional</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/contato" routerLinkActive="active">Contato </a>
            </li>
            <!--li class="nav-item">
                <a class="nav-link">Cursos </a>
            </li-->
        </ul>
    </div>
</nav>
