<div class="container-fluid" >

    <div class="xadrez-bkg">
        <div class="container lead mt-5">
            O projeto <b>Xadrez no IF</b> objetiva criar um espaço e momento para a prática e estudo
            do xadrez no IF Sudeste MG - Manhuaçu. Durante o ano de 2020 o projeto foi conduzido de maneira remota sob estudos 
            na plataforma lichess.org (plataforma gratuita e online de xadrez).
        </div>
    
        <div class="container mt-5">
            <h2 class="text-center">Acompanhe os estudos do projeto:</h2>
            <div class="border mb-5 border-custom-primary secondary-shadow rounded-sm mt-5">
                <div class="card-group" *ngFor="let estudo of estudos | paginate: { itemsPerPage: ip, currentPage: p }; index as i">
    
                    <div class="card nobg border-0">
                        <div class="card-header">
                            <h3>{{estudo.titulo}}</h3>
                        </div>
                        <div class="card-body">
                            <p class="card-text">{{estudo.descricao}}</p>
                            <a href="{{estudo.lichessUrl}}" target="_blank" class="btn btn-custom-secondary">Acesse</a>
                        </div>
                    </div>
                </div>
    
                <div class="d-flex justify-content-end bg-secondary align-items-right">
                    
                    <pagination-controls class="my-pagination p-2" (pageChange)="p = $event"
                                            previousLabel="Anterior"
                                            nextLabel="Próximo"
                    
                    ></pagination-controls>
            </div>
            </div>
        </div>
    </div>

    <div class="xadrez-bkg-2 container-fluid d-flex justify-content-center">   
        <div class="container mt-5 p-2"> 
            <h2 class="text-center">Acompanhe as lives do projeto:</h2>
            <div class="row card-deck mt-5 mb-5">
                <div class="col-md-6 d-flex justify-content-center">
                    <div class="card nobg border-0  p-2">
                        <div class="card-header">
                            Live com o Grande Mestre de Xadrez Rafael Leitão
                        </div>
                        <div class="card-body">
                            <div class="embed-responsive embed-responsive-21by9">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/8la3r4HjDVA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-center">
                    <div class="card nobg border-0  p-2">
                        <div class="card-header">
                            Live com o Professor de Xadrez Cleber Santos
                        </div>
                        <div class="card-body">
                            <div class="embed-responsive embed-responsive-21by9">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/yZ_1uBF5t20" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
