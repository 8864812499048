import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  form:FormGroup

  nome:string
  email:string
  mensagem:string
  recaptchaReactive:string
  
  campoNomeInvalido:string
  campoEmailInvalido:string
  campoMensagemInvalido:string
  campoCaptchaInvalido:string
  mensagemEnviada:boolean

  constructor(private fb:FormBuilder) {
    this.mensagemEnviada = false
    this.form = fb.group({
      nome:'',
      email:'',
      mensagem:'',
      recaptchaReactive:''
    })
  }

  ngOnInit(): void {
    this.campoNomeInvalido = "";
    this.campoEmailInvalido = "";
    this.campoMensagemInvalido = "";
    this.campoCaptchaInvalido = "";
  }

  verificarCampos() {
    this.mensagemEnviada = true
    if(!this.form.get('nome').value) {
      this.campoNomeInvalido = "is-invalid";
      this.mensagemEnviada = false
    } else {
      this.campoNomeInvalido = "is-valid";
    }
    if(!this.form.get('email').value || !this.form.get('email').value.includes("@")) {
      this.campoEmailInvalido = "is-invalid";
      this.mensagemEnviada = false
    } else {
      this.campoEmailInvalido = "is-valid";
    }
    if(!this.form.get('mensagem').value) {
      this.campoMensagemInvalido = "is-invalid";
      this.mensagemEnviada = false
    } else {
      this.campoMensagemInvalido = "is-valid";
    }
    if(!this.form.get('recaptchaReactive').value) {
      this.campoCaptchaInvalido = "is-invalid"; 
      this.mensagemEnviada = false     
    } else {
      this.campoCaptchaInvalido = "is-valid";
    }
  }

  /*resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
   
  }*/

}
