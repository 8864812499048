<div class="container-fluid d-flex justify-content-center">

    <div class="container m-5 lead">
        <p>Olá, tudo bem? Nesta página você encontra um pouco da minha trajetória profissional...</p>

        <p class="text-left"> Concluí meu <b>Ensino Médio</b> na <b>Escola Estadual Comendador Mário Goulart Santiago</b> 
        (Pedralva/MG) em 2008. </p>

        <img src="../../../assets/perfil-2.png" class="float-left rounded-circle"> 

        <p class="text-right">Sou <b>Cientista da Computação</b> pela Universidade Federal de Lavras (UFLA 2009-2013)
        e <b>Mestre em Ciência de Computação e Matemática Computacional</b> pelo Instituto de 
        Ciências Matemáticas e de Computação da Universidade de São Paulo (ICMC-USP 2014-2016).
        </p>
        <p class="text-center"> 
        Trabalhei como <b>Desenvolvedor Mobile</b> e <b>Web</b>  na Empresa ONION Tecnologia. 
        </p>
        <p class="text-right">
        Entre 2018 e 2019 fui <b>professor substituto</b> no <b>Instituto Federal de Educação Ciência e Tecnologia do Sul de Minas - 
        <i>Campus</i> Poços de Caldas</b> (IFSULDEMINAS - Poços de Caldas) lecionando as disciplinas de Banco de Dados, Programação Orientada a Objetos, Programação Web, Montagem e Manutenção de Computadores, Engenharia de Software, Inteligência Artificial, Tópicos em Sistemas Inteligentes, Sistemas Distribuídos, Estrutura de Dados e Aplicativos Computacionais.   
        </p> 
        <p class="text-justify">
        Atualmente sou <b>professor do Ensino Básico Técnico e Técnológico (EBTT) efetivo no 
        Intituto Federal de Educação, Ciência e Tecnologia do Sudeste de Minas Gerais - <i>Campus</i> Manhuaçu </b>
        (IF Sudeste MG - Campus Manhuaçu), onde leciono as disciplinas de Desenvolvimento Web I, Desenvolvimento Web II, Desenvolvimento para Dispositivos Móveis e Framework Front-End.
        </p>
    
    </div> 
    
</div>

<div class="container bg-custom-primary">
    <hr>
</div>

<div class="container card-group d-flex flex-column">
    <div class="card nobg m-1 border-0">
        <div class="card-body">
          <h5 class="card-title">Currículo Lattes</h5>
          <p class="card-text">Aqui você pode acessar meu currículo completo.</p>
          <a href="http://lattes.cnpq.br/2984372366855974" target="_blank" class="btn btn-custom-secondary">Abrir</a>
        </div>
    </div>
    <div class="card nobg m-1  border-0">
        <div class="card-body">
        <h5 class="card-title">IF Sudeste MG</h5>
        <p class="card-text">Aqui você pode acessar o site oficial da instituição onde eu trabalho.</p>
        <a href="#" class="btn btn-custom-secondary">Abrir</a>
        </div>
    </div>
</div>
