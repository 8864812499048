<div class="container d-flex justify-content-center flex-column">

    <div class="jumbotron-fluid nobg mt-5">
        <h1 class="display-4">Entre em contato</h1>
        <p class="lead">Deixe sua mensagem...</p>
    </div>

    <form [formGroup]="form" class="border mb-5 p-4 border-custom-primary secondary-shadow rounded-sm">
        <div class="form-group">
            <label>
                Seu nome:
            </label>
            <input class="form-control {{campoNomeInvalido}}" type="text" formControlName="nome">
            <!--input class="form-control {{campoNomeInvalido}}" type="text" required name="nome" [(ngModel)]="nome"-->
            <div class="invalid-feedback" *ngIf="!nome">
                Por favor, verifique este campo.
            </div>
        </div>
        <div class="form-group">
            <label>
                Seu email:
            </label>
            <input class="form-control {{campoEmailInvalido}}" type="email" formControlName="email">
            <div class="invalid-feedback" *ngIf="!email || !email.includes('@')">
                Por favor, verifique este campo.
            </div>
        </div>
        <div class="form-group">
            <label>
                Sua mensagem:
            </label>
            <textarea rows="15" class="form-control {{campoMensagemInvalido}}" required name="mensagem" formControlName="mensagem"></textarea>
            <!--textarea rows="15" class="form-control {{campoMensagemInvalido}}" required name="mensagem" [(ngModel)]="mensagem"></textarea-->
            <div class="invalid-feedback" *ngIf="!mensagem">
                Sua mensagem não foi escrita.
            </div>
        </div>
        <div *ngIf="mensagemEnviada"  class="text-dark text-center bg-custom-secondary p-1">
            Obrigado por enviar sua mensagem.
        </div>
        <div class="g-recaptcha {{campoCaptchaInvalido}}">
            <!-- 
              the SITE_KEY is the sitekey from your admin console.
              use form control for validation
            -->
            <re-captcha  
                        formControlName="recaptchaReactive"
                        theme="dark"
                        siteKey="6LcgS_0ZAAAAAO8aTSaN1hbw9GgooE7oGoddCAUn">  
            </re-captcha>
          </div>
          <div class="invalid-feedback" *ngIf="!recaptchaReactive">
              Você é um robô?
          </div>
        <button class="btn btn-custom-secondary mt-3 align-self-start" (click)="verificarCampos()">Enviar</button>
    </form>
</div>
