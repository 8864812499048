import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContatoComponent } from './componentes/contato/contato.component';
import { DevWebIComponent } from './componentes/dev-web-i/dev-web-i.component';
import { FormacaoComponent } from './componentes/formacao/formacao.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { XadrezComponent } from './componentes/xadrez/xadrez.component';


const routes: Routes = [
  {path:"", component:InicioComponent},
  {path:"inicio", component:InicioComponent},
  {path:"formacao", component:FormacaoComponent},
  {path:"contato", component:ContatoComponent},
  {path:"xadrez", component:XadrezComponent},
  {path:"dev-web-i", component:DevWebIComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
