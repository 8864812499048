import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Estudo } from './estudo.model';

@Component({
  selector: 'app-xadrez',
  templateUrl: './xadrez.component.html',
  styleUrls: ['./xadrez.component.css']
})
export class XadrezComponent implements OnInit {
  
  p: number
  ip: number
  estudos: Estudo[]

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.p = 1
    this.ip = 3
    this.getJsonData().subscribe(dados => {this.estudos=dados.estudos})
  }

  getJsonData():Observable<any> {
    return this.http.get("../../assets/estudos.json")
  }

}
